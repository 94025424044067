.Main-Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background : url(../../images/bg-curvy-desktop.svg) no-repeat 50% 100%;
    background-size:  100% 35%;
}
.Main-Img{
   position: relative;
   width: 45%;
   z-index: 9999;
   flex: 1;
}
.Main-Img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Main-Title{
    color:  #FFFFFF;
    width: 53%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 999;
    margin-top: 90px;
    flex: 1;  
}
.Main-Title h3{
    font-family: 'Raleway', sans-serif; 
    font-weight: 700;
    font-size: 2.5em;
    z-index: 999;
    margin:0px 20px;
}
.Main-Title p{
    font-family: 'Open Sans', sans-serif; 
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 15px;
    z-index: 999;
    margin:40px 70px;
}
.Main-Title button{
    font-family: 'Raleway', sans-serif; 
    color:  #FFFFFF;
    font-weight: 700;
    font-size: 16px;
    /* width: 290px;
    height: 60px; */
    padding: 20px 100px;
    border-radius: 50px;
    border: none;
    background: linear-gradient(138.1deg, #63E1D9 100%, #34A0CD 100%);
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.15925);
    border-radius: 28px;
    z-index: 999;
    cursor: pointer;
}
.Main-Title button:hover{
    background: #8ADAE3;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.15925);
    border-radius: 28px;
}

@media screen and (max-width: 780px){
    .Main-Container{
        position: relative;
        /* background-color: #1B2330;
        background-color: #fff; */
        background-size:  100% auto;
    }
    .Main-Img{
        width: 90%;
     }
     .Main-Title{
         width: 90%;
         /* max-width: 338px; */
         margin-top:20px;
     }
    .Main-Title h3{
        font-size: 24px;
        margin:0px;
        line-height: 36px;
    }
    .Main-Title p{
      font-size: 14px;
      margin:20px;
      line-height: 21px;
    }
    .Main-Title button{
       padding: 20px 100px;
        margin-top: 10px;
    }
}