.Section{
    color: white;
    background-color: #181F2B;
    display: flex;
    justify-content: center;
    align-items: center;   
}
.Work-Section{
    display: flex;
    justify-content: center;
    align-items: center; 
    margin: 50px;
    width: 100%;
    max-width: 1440px;
}
.Work-Img{
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;
    margin: 50px;
}
.Work-Img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Work-Paragraf{
    flex: 1;
}
.Work-Paragraf .Paragraf{
    padding-left: 40px;
 padding-right: 60px;
}
.Work-Paragraf h2{
    font-family: 'Raleway', sans-serif; 
    font-weight: 700;
    font-size: 40px;
    padding-bottom: 19px;
    padding-right: 100px;
}
.Work-Paragraf p{
    font-family: 'Open Sans', sans-serif; 
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 19px;   
    padding-right:35px ;
}
.Work-Link{
    display: flex;
    align-items: flex-end;
}
.Work-Link a{
color: #62E0D9;
font-family: Open Sans;
font-family: 'Open Sans', sans-serif; 
font-weight: normal;
font-size: 16px;
text-underline-offset: 5px;
}
.Work-Icon{
padding-left: 5px;
}
.Work-Link a:hover{
    color: white;
}

@media screen and (max-width: 780px){
 
.Work-Section{
    flex-direction: column;
    width: 90%;
    margin: 50px 0px;
}
.Work-Img{
    margin: 0;
}

.Work-Paragraf{
    width: 100%;
}
.Work-Paragraf h2{
    font-size: 18px;
    padding-right: 0;
    padding-top: 50px;
    
}
.Work-Paragraf .Paragraf{
    padding-left: 0;
    padding-right: 0;
}
.Work-Paragraf p{
    padding-right: 0;
}
.Work-Link a{
    font-size: 16px;
    padding-top: 5px;
}
}