@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&family=Open+Sans:wght@200;400;700&family=Raleway:wght@200;400;700&display=swap');


*{
    box-sizing: border-box;
    margin: 0;
}

.Main{
    background-color: #1B2330;
}