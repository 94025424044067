.Navbar{
    display: flex;
    width: 100%;
    height: 100px;
    padding-top:50px;
    padding-bottom: 80px;
}
.Navbar-Logo{
  flex: 4;
  Width :176px;
  height: 52px;
  margin-left: 80px;
}
.Navbar-Link{
    display: flex;
    justify-content: flex-end;
    flex: 2;
    font-family: 'Raleway', sans-serif;
    margin-right: 90px ;
}
.Navbar-Link a{
    margin-left: 55px;
    font-size: 18px;
    color:  #FFFFFF;
    text-decoration: none;
    cursor: pointer;
}
.Navbar-Link a:hover{
    text-decoration: underline;
}
@media screen and (max-width: 780px){
    .Navbar{
        display: flex;
        align-items: center;
        width: 100%;
        height: 100px;
        padding-top:35px;
        padding-left: 20px;
    }
    .Navbar-Logo{
        flex: 1;
        Width :80px;
        height: 24px;
        margin-left: 0;
    }
    .Navbar-Logo img{
        Width :80px;
        height: 24px;
    }
    .Navbar-Link{
        margin-right: 20px;
    }
    .Navbar-Link a{
        font-size: 12px;
        margin-left: 30px;
        font-weight: 200;
    }
}